
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue';
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  }
})
export default class open_invitation extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name);
  }

  tableA_all_data=[]

tableA_total= 0;
tableA_search_data = {
  name: '',
  code: '',
  project_name: ''

}

rule_tableA_search_data = {

}

tableA_detail_dialog = {
  open: false,
  title: '详情'
}

tableA_detail_data = {
  name: '',
  res_source: 0,
  project_name: '',
  type: 0,
  industry_category: '',
  modality: 0,
  res_source_unit: '',
  source_unit_nature: 0,
  related_industries: '',
  contact: '',
  phone: ''

}

tableA_edit_dialog = {
  open: false,
  title: '申请评估'
}

tableA_edit_data = {
  id: 0

}

rule_tableA_edit_data = {

}

res_source_276 = new Map<any, string>([[1, '纵向课题'], [2, '横向课题'], [3, '其他']])
type_278 = new Map<any, string>([[1, '应用技术'], [2, '基础理论'], [3, '软课题研究'], [4, '教育教学'], [5, '创新能力与条件建设'], [6, '技术转移与推广'], [7, '文学艺术实践'], [8, '其它']])
modality_280 = new Map<any, string>([[1, '新方法'], [2, '新产品'], [3, '新工艺'], [4, '新材料/配方'], [5, '新设备/工具'], [6, '软件系统'], [7, '新品种'], [8, '其他']])
source_unit_nature_282 = new Map<any, string>([[1, '高效,2'], [3, '其他事业单位'], [4, '社会组织'], [5, '国企'], [6, '名企'], [7, '外企'], [8, '混合所有制企业'], [9, '其他']])
apply_286 = new Map<any, string>([[true, '已申请'], [false, '未申请']])

tableA_get_all_data(offset = 0, length = 10) {
  const postData = {
    offset: offset || 0,
    length: length || 10,
    name: this.tableA_search_data.name,
    code: this.tableA_search_data.code,
    project_name: this.tableA_search_data.project_name

  }
  Api.http_open_invitationtableA0(postData).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.tableA_all_data = res.data.data
      this.tableA_total = res.data.total
    }
  })
}
tableA_detail_init(row) {

  Api.http_open_invitationtableAget4({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.tableA_detail_data.name = res.data.name;
      this.tableA_detail_data.res_source = res.data.res_source;
      this.tableA_detail_data.project_name = res.data.project_name;
      this.tableA_detail_data.type = res.data.type;
      this.tableA_detail_data.industry_category = res.data.industry_category;
      this.tableA_detail_data.modality = res.data.modality;
      this.tableA_detail_data.res_source_unit = res.data.res_source_unit;
      this.tableA_detail_data.source_unit_nature = res.data.source_unit_nature;
      this.tableA_detail_data.related_industries = res.data.related_industries;
      this.tableA_detail_data.contact = res.data.contact;
      this.tableA_detail_data.phone = res.data.phone;
      this.tableA_detail_dialog.open = true;

    }
  })

}
tableA_detail_cancle() {
  this.tableA_detail_dialog.open = false;
}
tableA_detail_ok() {
  this.tableA_detail_dialog.open = false;
}
tableA_edit_init(row) {

  Api.http_open_invitationtableAget5({ id: row.id }).then(res => {
    if (res.code !== '200') {
      this.$message.error(res.message)
    } else {
      this.tableA_edit_data.id = row.id;
      this.tableA_edit_dialog.open = true;

    }
  })

}
tableA_edit_cancle() {
  this.tableA_edit_dialog.open = false;
}
tableA_edit_ok() {
  this.$refs.ref_tableA_edit_data.validate(valid => {
    if (valid) {
      const postData = {
        id: this.tableA_edit_data.id

      }
      Api.http_open_invitationtableA5(postData).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.tableA_edit_dialog.open = false
          this.tableA_get_all_data(0, 10)
          this.$message.success(JSON.stringify(res.message))
        }
      })
    }
  })
}
tableA_init() {
  this.tableA_get_all_data(0, 10);

}
created() {
  this.tableA_init();

}

}
